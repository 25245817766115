<template>
  <div style="width: 100%">
    <v-row>
      <v-col v-if="!$store.state.user.trainerHasSignature && $store.state.user.loggedIn">
        <h2>Firma a continuación para poder realizar las sesiones.</h2>
        <p class="mb-6">
          Esta firma se utilizará en la firma de documentos no biométricos.<br />
          Sólo tendrás que introducirla esta vez, por favor, asegúrate que sea correcta.
        </p>
        <trainer-signature-form></trainer-signature-form>
      </v-col>
      <v-col v-else>
        <v-data-table
          :loading="loadingCourses"
          :headers="headers"
          :items="items"
          fixed-header
          hide-default-footer
          :items-per-page="-1"
        >
          <template #item.info="{ item }">
            <session-info-dialog :session-data="item"></session-info-dialog>
          </template>

          <template #item.actions="{ item }">
            <v-tooltip bottom v-if="!item.is_next_session">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" size="18" dark v-bind="attrs" v-on="on" class="mr-2">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>Hay sesiones anteriores pendientes</span>
            </v-tooltip>

            <v-btn
              :to="{ name: 'trainerSession', params: { id: item.id } }"
              :disabled="!item.is_next_session"
              color="primary"
              class="mr-2 prd-crud-edit-button px-1"
              x-small
              depressed
            >
              <v-icon small>mdi-arrow-right-thick</v-icon>
            </v-btn>
          </template>

          <template #no-data> No hay sesiones pendientes </template>

          <template #item.session_number="{ item }">
            {{ item.session_number }} / {{ item.estimated_sessions_quantity }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  components: {
    sessionInfoDialog: () => {
      return import("@/modules/cardioguard-trainer/components/views/SessionsSessionDataDialog");
    },
    trainerSignatureForm: () =>
      import(
        "@/modules/cardioguard-trainer/components/views/trainer-sessions-pending/trainerSignatureForm"
      ),
  },
  data: () => ({
    items: [],
    loadingCourses: true,
    headers: [
      { value: "info", text: "", sortable: false },
      { value: "actions", text: "", sortable: false, width: 115, align: "right" },
      { value: "course_code", text: "Código", sortable: false },
      { value: "date", text: "Fecha", sortable: false },
      { value: "centers", text: "Centro/s", sortable: false },
      { value: "course_category", text: "Tipo", sortable: false },
      { value: "session_number", text: "Sesión", sortable: false },
      { value: "assistances_count", text: "Alumnos", sortable: false },
      { value: "clients", text: "Cliente/s", sortable: false },
    ],
  }),
  mounted() {
    this.getPendingSessions();
  },
  methods: {
    async getPendingSessions() {
      try {
        const response = await this.$store.state.currentService.getPendingSessions();
        this.items = response.data;
      } catch {
        this.$notifyError(undefined, "Error al descargar los cursos");
      } finally {
        this.loadingCourses = false;
      }
    },
  },
};
</script>

<style scoped></style>
