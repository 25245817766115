var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-row',[(!_vm.$store.state.user.trainerHasSignature && _vm.$store.state.user.loggedIn)?_c('v-col',[_c('h2',[_vm._v("Firma a continuación para poder realizar las sesiones.")]),_c('p',{staticClass:"mb-6"},[_vm._v(" Esta firma se utilizará en la firma de documentos no biométricos."),_c('br'),_vm._v(" Sólo tendrás que introducirla esta vez, por favor, asegúrate que sea correcta. ")]),_c('trainer-signature-form')],1):_c('v-col',[_c('v-data-table',{attrs:{"loading":_vm.loadingCourses,"headers":_vm.headers,"items":_vm.items,"fixed-header":"","hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.info",fn:function(ref){
var item = ref.item;
return [_c('session-info-dialog',{attrs:{"session-data":item}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.is_next_session)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary","size":"18","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v("Hay sesiones anteriores pendientes")])]):_vm._e(),_c('v-btn',{staticClass:"mr-2 prd-crud-edit-button px-1",attrs:{"to":{ name: 'trainerSession', params: { id: item.id } },"disabled":!item.is_next_session,"color":"primary","x-small":"","depressed":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-arrow-right-thick")])],1)]}},{key:"no-data",fn:function(){return [_vm._v(" No hay sesiones pendientes ")]},proxy:true},{key:"item.session_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.session_number)+" / "+_vm._s(item.estimated_sessions_quantity)+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }